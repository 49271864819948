import React from 'react';
import { PageHeader, Typography } from '@iqmetrix/antd';
import { PageLayout } from '@iqmetrix/layout';
import { EnvironmentSelector } from 'components/EnvironmentSelector';
import config from 'shared/config';
const { Paragraph } = Typography;

export const LandingPage : React.FC = () =>
<PageLayout size="full">
{{
    header: <PageHeader title={config.appHeader}></PageHeader>,
    content: [
        {
            primary:
            <div>
                <Paragraph> Select environment to schedule update(s):</Paragraph>
                <EnvironmentSelector />
            </div>
        }
    ]
}}
</PageLayout>;