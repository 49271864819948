import { Alert, PageHeader, Skeleton } from "@iqmetrix/antd";
import { PageLayout } from "@iqmetrix/layout";
import { Typography, Button } from "@iqmetrix/antd";
import React from "react";
import { Option } from "functional-ts-primitives";
import { AuthError } from "@azure/msal-browser";
const { Paragraph } = Typography;

export const UnauthenticatedPage : React.FC<{ authError: Option<AuthError>, loginAction: () => void}> = props => 
<PageLayout size="full">
{{
    header: <PageHeader title="Update scheduler"></PageHeader>,
    messages: 
        props.authError.match( 
            authError=>
                <Alert
                    type="error"
                    message="An error occurred when attempting to log you in." 
                    description={
                        <div>
                            <Paragraph aria-label="error code">Error Code: {authError.errorCode}</Paragraph>
                            <Paragraph aria-label="error description">{authError.message}</Paragraph>
                        </div>
                    }
                    closable
                    aria-label="authentication error alert"/>,
            () => null),
    content: [
        {
            primary:
            <div>
                <Paragraph>You are not authenticated to access the update scheduler. <Button type="link" size="small" onClick={props.loginAction} aria-label="sign in">Sign in here.</Button></Paragraph>
            </div>
        }
    ]
}}
</PageLayout>;