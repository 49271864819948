import { DownOutlined, Dropdown, Menu } from '@iqmetrix/antd';
import React from 'react';
import config from 'shared/config';

export const EnvironmentSelector : React.FC = () =>
<Dropdown overlay={environmentSelectMenu()} trigger={["click"]}>
    <a href="#" aria-label="select environment dropdown">
    Select environment <DownOutlined />
    </a>
</Dropdown>

const environmentSelectMenu = () =>
<Menu>
    {menuItem("Live", config.appUris.prod)}
    {menuItem("Release", config.appUris.rc)}
    {menuItem("Test", config.appUris.int)}
    { 
        config.environment == "dev" && 
        menuItem("Development", config.appUris.dev)
    }
</Menu>;

const menuItem = (text: string, endpoint: string) =>
<Menu.Item key={text} onClick={() => window.location.href=endpoint} aria-label={`${text} environment`}>
    {text}
</Menu.Item>