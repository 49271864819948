import { Configuration, RedirectRequest, PublicClientApplication } from "@azure/msal-browser";
import config from 'shared/config';

const msalConfig : Configuration = {
    auth: config.authOptions
};

export const redirectRequest : RedirectRequest = {
  scopes: ['User.Read']
}

export const msalInstance = new PublicClientApplication(msalConfig);