import { PageHeader, Skeleton } from "@iqmetrix/antd";
import { PageLayout } from "@iqmetrix/layout";
import React from "react";

export const LoadingPage : React.FC = () =>
<PageLayout size="full">
{{
    header: <PageHeader title="Update scheduler"></PageHeader>,
    content: [
        {
            primary: <Skeleton  loading active />
        }
    ]
}}
</PageLayout>;