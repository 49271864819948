import { Layout } from "@iqmetrix/antd";
import { TopBar } from "components/TopBar";
import { LoadingPage } from "pages/LoadingPage";
import { UnauthenticatedPage } from "pages/UnauthenticatedPage";
import { LandingPage } from "pages/LandingPage";
import { Option } from "functional-ts-primitives";
import {  MsalAuthenticationResult, MsalAuthenticationTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import { msalInstance, redirectRequest } from "shared/authConfig";
import { InteractionType } from "@azure/msal-browser";

const { Content } = Layout

const LoadingComponent = () =>
<Layout>
  <TopBar accountInfo={Option.none()} logoutAction={() => Promise.resolve()}></TopBar>
  <Content>
      <LoadingPage />
  </Content>
</Layout>

const ErrorComponent = (result: MsalAuthenticationResult) =>
<Layout>
  <TopBar accountInfo={Option.none()} logoutAction={() => Promise.resolve()}></TopBar>
  <Content>
      <UnauthenticatedPage authError={Option.fromNullable(result.error)} loginAction={result.login} />
  </Content>
</Layout>

const AuthenticatedComponent = () => {
  const { accounts } = useMsal()

  return <Layout>
    <TopBar accountInfo={Option.fromNullable(accounts[0])} logoutAction={() => msalInstance.logoutRedirect({ account: accounts[0] })}></TopBar>
    <Content>
        <LandingPage />
    </Content>
  </Layout>
}

export const App = () =>
<MsalProvider instance={msalInstance}>
  <MsalAuthenticationTemplate
    interactionType={InteractionType.Redirect}
    authenticationRequest={redirectRequest}
    loadingComponent={LoadingComponent}
    errorComponent={ErrorComponent}>
    <AuthenticatedComponent />
  </MsalAuthenticationTemplate>
</MsalProvider>;