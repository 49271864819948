import common from "../config/appsettings.json";
import dev from "../config/appsettings.dev.json";
import int from "../config/appsettings.int.json";
import rc from "../config/appsettings.rc.json";
import prod from "../config/appsettings.prod.json";
import test from "../config/appsettings.test.json";
import { BrowserAuthOptions } from "@azure/msal-browser";
import merge from "ts-deepmerge";

interface Configuration {
  environment: string
  authOptions: BrowserAuthOptions
  appUris: {
    dev: string
    int: string
    rc: string
    prod: string
  },
  appHeader : string
  appInsights: { 
    instrumentationKey: string
    enabled: boolean
  }
}

function getConfig() : Configuration {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "dev": return merge(common, dev);
    case "int": return merge(common, int);
    case "rc": return merge(common, rc);
    case "prod": return merge(common, prod);
    case "test": return merge(common, test);
    default:
      throw new Error(`Invalid environment ${process.env.REACT_APP_ENVIRONMENT}`);
  };
}

const config : Configuration = getConfig();
export default config;
